import { FC } from "react"
import {
  Input,
  InputLabel,
  Select,
  SelectOption,
  UnitsInput,
} from "@appia/ui-components"
import InputWithError from "src/components/InputWithError"
import { FormField } from "@appia/form-data"
import { MinorSubClassesRequest } from "@appia/api"
import { ValueAndCurrency } from "./parsers"
import { currencyOptions } from "./mockData"
import usePolicyReference from "./utils/usePolicyReference"
import LayerDetailsPanel from "./LayerDetailsPanel"

const eeaOrRowOptions: SelectOption[] = [
  { value: "eea", label: "EEA" },
  { value: "row", label: "ROW" },
]

const energyGroupClassOptions: SelectOption[] = [
  { value: "energy_operational", label: "Energy Operational" },
  { value: "energy_construction", label: "Energy Construction" },
  { value: "energy_gom_wind", label: "Energy GOM Wind" },
  { value: "energy_liability", label: "Energy Liability" },
  { value: "energy_midstream", label: "Energy Midstream" },
  { value: "energy_renewable", label: "Energy Renewable" },
]

interface ContractSectionsEnergyPanelProps {
  policyReference: FormField<string, string> & { showError: boolean }
  onChangePolicyReference: (value: string) => void
  eeaOrRow: FormField<boolean, boolean | null> & { showError: boolean }
  onChangeEeaOrRow: (value: boolean | null) => void
  premiumAllocationValueAndCurrency: FormField<
    ValueAndCurrency,
    ValueAndCurrency
  > & { showError: boolean }
  onChangePremiumAllocationValueAndCurrency: (value: ValueAndCurrency) => void
  premiumAllocationPercent: FormField<number, number | null> & {
    showError: boolean
  }
  onChangePremiumAllocationPercent: (value: number | null) => void
  limitAllocationValueAndCurrency: FormField<
    ValueAndCurrency,
    ValueAndCurrency
  > & { showError: boolean }
  onChangeLimitAllocationValueAndCurrency: (value: ValueAndCurrency) => void
  limitAllocationPercent: FormField<number, number | null> & {
    showError: boolean
  }
  onChangeLimitAllocationPercent: (value: number | null) => void
  groupClass: FormField<string, string> & { showError: boolean }
  onChangeGroupClass: (value: string) => void
  minorClass: FormField<string, string> & { showError: boolean }
  onChangeMinorClass: (value: string) => void
  subClass: FormField<string, string | null> & { showError: boolean }
  onChangeSubClass: (value: string) => void
  brokerIndustry: string
  classesRequest: MinorSubClassesRequest
}

const ContractSectionsEnergyPanel: FC<ContractSectionsEnergyPanelProps> = ({
  policyReference,
  onChangePolicyReference,
  eeaOrRow,
  onChangeEeaOrRow,
  premiumAllocationValueAndCurrency,
  onChangePremiumAllocationValueAndCurrency,
  premiumAllocationPercent,
  onChangePremiumAllocationPercent,
  limitAllocationValueAndCurrency,
  onChangeLimitAllocationValueAndCurrency,
  limitAllocationPercent,
  onChangeLimitAllocationPercent,
  groupClass,
  onChangeGroupClass,
  minorClass,
  onChangeMinorClass,
  subClass,
  onChangeSubClass,
  brokerIndustry,
  classesRequest,
}) => {
  const { isEditing, originalReference, handleChangeOfPolicyReference } =
    usePolicyReference(policyReference, onChangePolicyReference)

  return (
    <div>
      <section className="ml-6 mb-8 mt-4">
        <InputLabel label="Policy Reference" className="!mb-0 py-2 !font-bold">
          <div className="mb-2 w-1/2">
            <InputWithError
              errorTestId="policy-reference-error-message"
              formField={policyReference}
              showFormFieldErrors={policyReference.showError}
              input={errorId => (
                <Input
                  hasError={!!errorId}
                  id="policy-reference"
                  data-testid="policy-reference"
                  onChange={handleChangeOfPolicyReference}
                  type="text"
                  value={policyReference.raw}
                />
              )}
            />
            {isEditing && (
              <div className="text-gray-600 mb-2 text-sm">
                Original Policy Reference: {originalReference}
              </div>
            )}
          </div>
        </InputLabel>
        <InputLabel label="EEA or ROW" className="!mb-0 py-2 !font-bold">
          <div className="mb-2 w-1/2">
            <InputWithError
              errorTestId="eea-or-row-error-message"
              formField={eeaOrRow}
              showFormFieldErrors={eeaOrRow.showError}
              input={errorId => (
                <Select
                  errorMessageId={errorId}
                  options={eeaOrRowOptions}
                  selectedValue={
                    eeaOrRow.raw !== null
                      ? eeaOrRow.raw
                        ? "eea"
                        : "row"
                      : null
                  }
                  onSelect={(value: string) => {
                    onChangeEeaOrRow(
                      value === "eea" ? true : value === "row" ? false : null,
                    )
                  }}
                  placeholder=""
                />
              )}
            />
          </div>
        </InputLabel>
        <InputLabel label="Group Class" className="!mb-0 py-2 !font-bold">
          <div className="mb-2 w-1/2">
            <InputWithError
              data-testid="group-class-select"
              errorTestId="group-class-error-message"
              formField={groupClass}
              showFormFieldErrors={groupClass.showError}
              input={errorId => (
                <Select
                  errorMessageId={errorId}
                  options={energyGroupClassOptions}
                  selectedValue={groupClass.raw}
                  onSelect={onChangeGroupClass}
                  placeholder="Select a group class"
                />
              )}
            />
          </div>
        </InputLabel>
        <LayerDetailsPanel
          minorClass={minorClass}
          subClass={subClass}
          onChangeMinorClass={onChangeMinorClass}
          onChangeSubClass={onChangeSubClass}
          brokerIndustry={brokerIndustry}
          classesRequest={classesRequest}
        />
        <InputLabel
          label="Premium Allocation Value"
          className="!mb-0 py-2 !font-bold"
        >
          <div className="flex w-3/4">
            <div className="w-8/12">
              <InputWithError
                errorTestId="premium-allocation-value-error-message"
                formField={premiumAllocationValueAndCurrency}
                showFormFieldErrors={
                  premiumAllocationValueAndCurrency.showError
                }
                input={errorId => (
                  <UnitsInput
                    errorMessageId={errorId}
                    id="currency-select"
                    labelId="currency-select-label"
                    amountTestId="premium-allocation-value"
                    unitsTestId="premium-allocation-currency"
                    onChange={onChangePremiumAllocationValueAndCurrency}
                    units={currencyOptions.map(option => option.label)}
                    value={{
                      amount: premiumAllocationValueAndCurrency.raw.amount,
                      unit: premiumAllocationValueAndCurrency.raw.unit,
                    }}
                    precision={2}
                  />
                )}
              />
            </div>
            <span className="mx-2.5 flex h-8 items-center text-lg font-bold">
              /
            </span>
            <div className="w-3/12">
              <InputWithError
                errorTestId="premium-allocation-percent-error-message"
                formField={premiumAllocationPercent}
                showFormFieldErrors={premiumAllocationPercent.showError}
                input={errorId => (
                  <UnitsInput
                    errorMessageId={errorId}
                    id="percentage-select"
                    labelId="percentage-select-label"
                    amountTestId="premium-allocation-percent"
                    onChange={event => {
                      onChangePremiumAllocationPercent(event.amount)
                    }}
                    units={"%"}
                    value={{
                      amount: premiumAllocationPercent.raw,
                      unit: null,
                    }}
                    precision={2}
                  />
                )}
              />
            </div>
          </div>
        </InputLabel>
        <InputLabel
          label="Limit Allocation Value"
          className="!mb-0 py-2 !font-bold"
        >
          <div className="flex w-3/4">
            <div className="w-8/12">
              <InputWithError
                errorTestId="limit-allocation-value-error-message"
                formField={limitAllocationValueAndCurrency}
                showFormFieldErrors={limitAllocationValueAndCurrency.showError}
                input={errorId => (
                  <UnitsInput
                    errorMessageId={errorId}
                    id="currency-select"
                    labelId="currency-select-label"
                    amountTestId="limit-allocation-value"
                    unitsTestId="limit-allocation-currency"
                    onChange={onChangeLimitAllocationValueAndCurrency}
                    units={currencyOptions.map(option => option.label)}
                    value={{
                      amount: limitAllocationValueAndCurrency.raw.amount,
                      unit: limitAllocationValueAndCurrency.raw.unit,
                    }}
                    precision={2}
                  />
                )}
              />
            </div>
            <span className="mx-2.5 flex h-8 items-center text-lg font-bold">
              /
            </span>
            <div className="w-3/12">
              <InputWithError
                errorTestId="limit-allocation-percent-error-message"
                formField={limitAllocationPercent}
                showFormFieldErrors={limitAllocationValueAndCurrency.showError}
                input={errorId => (
                  <UnitsInput
                    errorMessageId={errorId}
                    id="percentage-select"
                    labelId="percentage-select-label"
                    amountTestId="limit-allocation-percent"
                    onChange={event => {
                      onChangeLimitAllocationPercent(event.amount)
                    }}
                    units={"%"}
                    value={{
                      amount: limitAllocationPercent.raw,
                      unit: null,
                    }}
                    precision={2}
                  />
                )}
              />
            </div>
          </div>
        </InputLabel>
      </section>
    </div>
  )
}

export default ContractSectionsEnergyPanel
