import { FC, useEffect, useRef, useState } from "react"
import * as RD from "@appia/remote-data"
import { InputLabel, Select } from "@appia/ui-components"
import InputWithError from "src/components/InputWithError"
import { FormField } from "@appia/form-data"
import {
  MINOR_CLASS_CODE_DESCRIPTION_MAPPING,
  MinorClassCode,
  MinorSubClassesRequest,
  SUB_CLASS_CODE_DESCRIPTION_MAPPING,
  SubClassCode,
} from "@appia/api"
import { useGetMinorAndSubClasses } from "src/swr"
import { validateClassesRequest } from "./utils/sectionFormUtils"

const LayerDetailsPanel: FC<{
  minorClass: FormField<string, string> & { showError: boolean }
  onChangeMinorClass: (value: string) => void
  subClass: FormField<string, string | null> & { showError: boolean }
  onChangeSubClass: (value: string) => void
  brokerIndustry: string | null
  classesRequest: MinorSubClassesRequest
}> = ({
  minorClass,
  onChangeMinorClass,
  subClass,
  onChangeSubClass,
  brokerIndustry,
  classesRequest,
}) => {
  const { request: minorSubClassResponse } = useGetMinorAndSubClasses(
    validateClassesRequest(classesRequest),
  )

  const [minorClassOptions, setMinorClassOptions] = useState<
    { value: MinorClassCode; label: string }[]
  >([])

  const [filteredSubClassOptions, setFilteredSubClassOptions] = useState<
    { value: SubClassCode; label: string }[]
  >([])

  const initialRender = useRef(true)
  const previousMinorClass = useRef(minorClass.raw)

  useEffect(() => {
    const isResponseSuccess = RD.isSuccess(minorSubClassResponse)
    const hasMinorClasses =
      isResponseSuccess &&
      minorSubClassResponse.data?.minor_classes !== undefined
    if (hasMinorClasses) {
      const minorClassesData = minorSubClassResponse.data.minor_classes

      const minorClassesForInceptionYear = Object.keys(minorClassesData).map(
        key => key.toUpperCase(),
      )
      const minorClassOptions = Object.entries(
        MINOR_CLASS_CODE_DESCRIPTION_MAPPING,
      )
        .filter(([, label]) => {
          const upperLabel = (label as string).toUpperCase()
          return minorClassesForInceptionYear.includes(upperLabel)
        })
        .map(([key, label]) => ({
          value: key as MinorClassCode,
          label: (label as string).toUpperCase(),
        }))

      setMinorClassOptions(minorClassOptions)

      if (minorClass.raw && minorClassOptions.length > 0) {
        const selectedMinorClass =
          MINOR_CLASS_CODE_DESCRIPTION_MAPPING[minorClass.raw as MinorClassCode]

        const minorClassesDataUpperCase = Object.keys(minorClassesData).reduce(
          (acc, key) => {
            acc[key.toUpperCase()] = minorClassesData[key].map(
              (subClass: string) => subClass.toUpperCase(),
            )
            return acc
          },
          {} as Record<string, string[]>,
        )

        const subClassesOfSelectedMinorClass =
          minorClassesDataUpperCase[selectedMinorClass.toUpperCase()]

        if (
          subClassesOfSelectedMinorClass &&
          subClassesOfSelectedMinorClass.length > 0
        ) {
          const filteredSubClasses = Object.entries(
            SUB_CLASS_CODE_DESCRIPTION_MAPPING,
          )
            .filter(([, label]) => {
              const upperLabel = (label as string).toUpperCase()
              return subClassesOfSelectedMinorClass.includes(upperLabel)
            })
            .map(([key, label]) => ({
              value: key as SubClassCode,
              label: (label as string).toUpperCase(),
            }))

          setFilteredSubClassOptions(filteredSubClasses)
          if (filteredSubClasses) {
            setFilteredSubClassOptions(filteredSubClasses)
          } else {
            setFilteredSubClassOptions([])
          }
        } else {
          setFilteredSubClassOptions([])
        }
      } else {
        setFilteredSubClassOptions([])
      }
    }
  }, [minorClass.raw, minorSubClassResponse])

  useEffect(() => {
    if (
      !initialRender.current &&
      minorClass.raw !== previousMinorClass.current
    ) {
      onChangeSubClass("")
    }
    previousMinorClass.current = minorClass.raw
    initialRender.current = false
  }, [minorClass.raw, onChangeSubClass])

  return (
    <section className="flex w-3/4 flex-col gap-2 pl-6 pb-8 pt-4">
      <InputLabel label="Minor Class" className="!mb-0 py-2 !font-bold">
        <InputWithError
          errorTestId="minor-class-error-message"
          formField={minorClass}
          showFormFieldErrors={minorClass.showError}
          input={errorId => (
            <Select
              errorMessageId={errorId}
              options={minorClassOptions}
              selectedValue={minorClass.raw}
              onSelect={onChangeMinorClass}
              placeholder="Select a minor class"
            />
          )}
        />
      </InputLabel>
      <InputLabel label="Sub Class" className="!mb-0 py-2 !font-bold">
        <InputWithError
          errorTestId="sub-class-error-message"
          formField={subClass}
          showFormFieldErrors={subClass.showError}
          data-testid="sub-class"
          input={errorId => (
            <Select
              errorMessageId={errorId}
              options={filteredSubClassOptions}
              selectedValue={subClass.raw}
              onSelect={onChangeSubClass}
              placeholder="Select a sub class"
            />
          )}
        />
      </InputLabel>
      <p className="mt-1 text-lg text-otto-grey-700">
        Broker selected industry: {brokerIndustry ?? "N/A"}
      </p>
    </section>
  )
}

export default LayerDetailsPanel
